<template>
  <div>
    <v-card flat class="pa-3 mt-2">
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LIST OF DECEASED</h4></v-toolbar-title>
      </v-toolbar>
      <v-row class="mt-4">
        <v-col
          md="12"
          cols="12"
        >
          <v-select
            v-model="mode_of_report"
            class="mx-3 pt-6"
            :items="['Yearly','Semi-Annual','Quarterly','Monthly']"
            label="Mode of Reports"
            required
            dense
            @change="cahnage_mode"
          ></v-select>
        </v-col>
        <v-col
          md="6"
          cols="12"
        >
          <v-select v-if="mode_of_report==='Yearly' ||mode_of_report==='Semi-Annual'||mode_of_report==='Quarterly'"
                    v-model="month_of"
                    class="mx-3"
                    :items="month_of_items"
                    item-value="year"
                    item-text="year"
                    label="Year Of"
                    required
                    @change="monthly_remittances"
                    dense></v-select>
          <v-select v-if="mode_of_report==='Semi-Annual'||mode_of_report==='Quarterly'"
                    v-model="ddaattaa"
                    class="mx-3"
                    :items="data_ittee"
                    label="Data"
                    required
                    @change="monthly_remittances"
                    dense
          ></v-select>
          <v-select v-if="mode_of_report==='Monthly'"
                    v-model="month_of"
                    class="mx-3"
                    :items="month_of_items"
                    item-value="month_of"
                    item-text="month_of"
                    label="Month Of"
                    required
                    @change="monthly_remittances"
                    dense
          ></v-select>
        </v-col>
        <!--        <v-col-->
        <!--          md="6"-->
        <!--          cols="12"-->
        <!--        >-->
        <!--          <v-select-->
        <!--            v-model="category"-->
        <!--            class="mx-3"-->
        <!--            :items="['Client','Charges']"-->
        <!--            label="Category"-->
        <!--            required-->
        <!--            @change="monthly_remittances"-->
        <!--            dense-->
        <!--          ></v-select>-->
        <!--        </v-col>-->
      </v-row>
      <v-card-text>
        <v-simple-table dense>
          <template v-slot:top>
            <v-toolbar flat>
              <span class="headline font-weight-light ">
                                                Print
                                                <v-icon
                                                  class="mr-2"
                                                  color="warning"
                                                  @click="print_list_of_service()"
                                                >
                                                {{icons.mdiPrinter}}
                                                </v-icon>
                                            </span>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-toolbar-title class="font-weight-light">Total Casket Price:
                {{total_casket_price}}
              </v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-toolbar-title class="font-weight-light">Total Charges:
                {{total_charges}}
              </v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-toolbar-title class="font-weight-light">Total Amount:
                {{total_price}}
              </v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-toolbar-title class="font-weight-light">Total Service:
                {{total_service}}
              </v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-uppercase">FSC#</th>
              <th class="text-center text-uppercase">Name</th>
              <th class="text-center text-uppercase">Date of Service</th>
              <th class="text-center text-uppercase">Date of Internment</th>
              <th class="text-center text-uppercase">Mode of Service</th>
              <th class="text-center text-uppercase">Details</th>
              <th class="text-center text-uppercase">Casket Type</th>
              <th class="text-center text-uppercase">Casket Price</th>
              <th class="text-center text-uppercase">Total Charges</th>
              <th class="text-center text-uppercase">Total Payables</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in list_of_deceased_items" :key="item.id">
              <td>{{ item.fsc_no }}</td>
              <td class="text-center">
                {{ item.last_name+', '+item.first_name+' '+item.middle_name }}
              </td>
              <td class="text-center">
                {{ item.date_of_service }}
              </td>
              <td class="text-center">
                {{ item.date_of_internment }}
              </td>
              <td class="text-center">
                {{ item.mode_of_service}}
              </td>
              <td class="text-center">
                {{ (item.damayan_branch===null?'':item.damayan_branch+' '+item.maf_no)
                +(item.kaagapay===null?'':item.kaagapay.last_name+', '+item.kaagapay.first_name)}}
              </td>
              <td class="text-center">
                {{ item.casket_type}}
              </td>
              <td class="text-center">
                {{ formatPrice(item.casket_price) }}
              </td>
              <td class="text-center">
                {{ formatPrice(
                parseFloat(item.kilometer_excess)
                +parseFloat(item.embalming_excess)
                +parseFloat(item.viewing_excess)
                +parseFloat(item.shipment_fee)
                +parseFloat(item.tribute_fee)
                +parseFloat(item.hospital_bill)
                +parseFloat(item.other_charges)
                +parseFloat(item.accomodation_charge)
                ) }}
              </td>
              <td class="text-center">
                {{ formatPrice(parseFloat(item.total)+parseFloat(item.ho_share))}}
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline, mdiPrinter} from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      month_of: '',
      month_of_items: [],
      data_ittee: [],
      category: '',
      ddaattaa: '',
      mode_of_report: '',

      list_of_deceased_items: [],
      is_deleting: false,
      total_casket_price: '0.00',
      total_charges: '0.00',
      total_price: '0.00',
      total_service: '0',
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiPrinter,
        },
      }
    },
    created() {
      this.get_transaction_month_history({
        branch_id: this.branch_id,
      })
        .then(response => {
          this.month_of_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    data() {
      return initialState()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['branch_id', 'month_start', 'month_end', 'user_id', 'branch_address', 'branch_contact_no', 'branch']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('transaction_months_history', ['get_transaction_month_history']),
      ...mapActions('deceased_client', ['get_list_of_deceased']),
      cahnage_mode() {
        this.month_of = ''
        this.list_of_deceased_items = []
        this.data_ittee = []
        this.total_casket_price = '0.00'
        this.total_charges = '0.00'
        this.total_price = '0.00'
        this.total_service = '0'
        if (this.mode_of_report === 'Semi-Annual') {
          this.data_ittee = ['1st Semi', '2nd Semi']
        }
        if (this.mode_of_report === 'Quarterly') {
          this.data_ittee = ['1st Quarter', '2nd Quarter', '3rd Quarter', '4th Quarter']
        }
      },
      monthly_remittances() {
        const data = new FormData()
        data.append('branch_id', this.branch_id)
        data.append('month_of', this.month_of)
        data.append('ddaattaa', this.ddaattaa)
        data.append('mode_of_report', this.mode_of_report)
        this.get_list_of_deceased(data)
          .then(response => {
            this.list_of_deceased_items = response.data[0].deceased
            this.total_casket_price = response.data[0].casket_price
            this.total_charges = response.data[0].charges
            this.total_price = response.data[0].total
            this.total_service = response.data[0].count
          })
          .catch(error => {
            console.log(error)
          })
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      print_list_of_service() {
        var imgData = this.company_logo
        var payments_array = []
        var data_array = []
        var data = '(CLIENT)'
        var month = this.month_of
        if (this.category === 'Charges') {
          data = '(CHARGES)'
          data_array = [40, 120, 60, 40, 50, 40, 40, 40, 40, 40, 40, 70, 40]
          payments_array.push(
            [
              {text: 'FSC #', alignment: 'center', style: 'label'},
              {text: 'Name', alignment: 'center', style: 'label'},
              {text: 'Casket Type', alignment: 'center', style: 'label'},
              {text: 'Casket Price', alignment: 'center', style: 'label'},
              {text: 'Embalming', alignment: 'center', style: 'label'},
              {text: 'KM Excess', alignment: 'center', style: 'label'},
              {text: 'Viewing Excess', alignment: 'center', style: 'label'},
              {text: 'Shipment Fee', alignment: 'center', style: 'label'},
              {text: 'Tribute Fee', alignment: 'center', style: 'label'},
              {text: 'Hospital Bill', alignment: 'center', style: 'label'},
              {text: 'Others', alignment: 'center', style: 'label'},
              {text: 'Others(Specify)', alignment: 'center', style: 'label'},
              {text: 'Total', alignment: 'center', style: 'label'},
            ]
          )
          if (this.list_of_deceased_items.length > 0) {
            let total_casket_price = this.total_casket_price
            let total_charges = this.total_charges
            let total_price = this.total_price
            this.list_of_deceased_items.forEach(function (item, index, payment) {
              payments_array.push(
                [
                  {text: item.fsc_no, alignment: 'left'},
                  {
                    text: item.last_name + ', ' + item.first_name + ' ' + item.middle_name,
                    alignment: 'left'
                  },
                  {text: item.casket_type, alignment: 'left'},
                  {text: item.casket_price, alignment: 'left'},
                  {text: item.embalming_excess, alignment: 'left'},
                  {text: item.kilometer_excess, alignment: 'left'},
                  {text: item.viewing_excess, alignment: 'left'},
                  {text: item.shipment_fee, alignment: 'left'},
                  {text: item.tribute_fee, alignment: 'left'},
                  {text: item.hospital_bill, alignment: 'left'},
                  {text: item.other_charges, alignment: 'left'},
                  {text: item.others_reason, alignment: 'left'},
                  {text: item.total, alignment: 'left'},
                ]
              )
              if (Object.is(payment.length - 1, index)) {
                // execute last item logic
                payments_array.push(
                  [
                    {text: '', alignment: 'left', border: [false, false, false, false]},
                    {text: '', alignment: 'left', border: [false, false, false, false]},
                    {text: '', alignment: 'left', border: [false, false, false, false]},
                    {text: '', alignment: 'left', border: [false, false, false, false]},
                    {text: '', alignment: 'left', border: [false, false, false, false]},
                    {text: '', alignment: 'left', border: [false, false, false, false]},
                    {text: '', alignment: 'left', border: [false, false, false, false]},
                    {text: '', alignment: 'left', border: [false, false, false, false]},
                    {text: '', alignment: 'left', border: [false, false, false, false]},
                    {
                      text: 'Total:',
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: total_casket_price,
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                    {
                      text: total_charges,
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                    {
                      text: total_price,
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ]
                )
              }
            })
          } else {
            payments_array.push(
              [
                '----',
                '----',
                '----',
                '----',
                '----',
                '----',
                '----',
                '----',
                '----',
                '----',
                '----',
                '----',
                '----',
              ]
            )
          }
        } else {
          data = '(CLIENT)'
          data_array = [40, 120, 70, 20, 70, 70, 50, 40, 40, 40, 40, 40, 40]
          payments_array.push(
            [
              {text: 'FSC #', alignment: 'center', style: 'label'},
              {text: 'Name', alignment: 'center', style: 'label'},
              {text: 'Date of Birth', alignment: 'center', style: 'label'},
              {text: 'Age', alignment: 'center', style: 'label'},
              {text: 'Date of Service', alignment: 'center', style: 'label'},
              {text: 'Date of Interment', alignment: 'center', style: 'label'},
              {text: 'Casket Type', alignment: 'center', style: 'label'},
              {text: 'Days', alignment: 'center', style: 'label'},
              {text: 'Km', alignment: 'center', style: 'label'},
              {text: 'Mode of Service', alignment: 'center', style: 'label'},
              {text: 'Casket Price', alignment: 'center', style: 'label'},
              {text: 'Charges', alignment: 'center', style: 'label'},
              {text: 'Total', alignment: 'center', style: 'label'},
            ]
          )
          if (this.list_of_deceased_items.length > 0) {
            let total_casket_price = this.total_casket_price
            let total_charges = this.total_charges
            let total_price = this.total_price
            this.list_of_deceased_items.forEach(function (item, index, payment) {
              payments_array.push(
                [
                  {text: item.fsc_no, alignment: 'left'},
                  {
                    text: item.last_name + ', ' + item.first_name + ' ' + item.middle_name,
                    alignment: 'left'
                  },
                  {text: item.date_of_birth, alignment: 'left'},
                  {text: item.age, alignment: 'left'},
                  {text: item.date_of_service, alignment: 'left'},
                  {text: item.date_of_internment, alignment: 'left'},
                  {text: item.casket_type, alignment: 'left'},
                  {text: item.days_of_embalming, alignment: 'left'},
                  {text: item.kilometer, alignment: 'left'},
                  {text: item.mode_of_service, alignment: 'left'},
                  {text: item.casket_price, alignment: 'left'},
                  {
                    text:
                      parseFloat(item.kilometer_excess)
                      + parseFloat(item.embalming_excess)
                      + parseFloat(item.viewing_excess)
                      + parseFloat(item.shipment_fee)
                      + parseFloat(item.tribute_fee)
                      + parseFloat(item.hospital_bill)
                      + parseFloat(item.other_charges)
                      + parseFloat(item.accomodation_charge)
                    , alignment: 'left'
                  },
                  {text: item.total, alignment: 'left'},
                ]
              )
              if (Object.is(payment.length - 1, index)) {
                // execute last item logic
                payments_array.push(
                  [
                    {text: '', alignment: 'left', border: [false, false, false, false]},
                    {text: '', alignment: 'left', border: [false, false, false, false]},
                    {text: '', alignment: 'left', border: [false, false, false, false]},
                    {text: '', alignment: 'left', border: [false, false, false, false]},
                    {text: '', alignment: 'left', border: [false, false, false, false]},
                    {text: '', alignment: 'left', border: [false, false, false, false]},
                    {text: '', alignment: 'left', border: [false, false, false, false]},
                    {text: '', alignment: 'left', border: [false, false, false, false]},
                    {text: '', alignment: 'left', border: [false, false, false, false]},
                    {
                      text: 'Total:',
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: total_casket_price,
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                    {
                      text: total_charges,
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                    {
                      text: total_price,
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ]
                )
              }
            })
          } else {
            payments_array.push(
              [
                '----',
                '----',
                '----',
                '----',
                '----',
                '----',
                '----',
                '----',
                '----',
                '----',
                '----',
                '----',
                '----',
                '----',
              ]
            )
          }
        }


        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: {
            width: 612,
            height: 936
          },
          pageOrientation: 'landscape',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE MEMORIAL CHAPEL CO.',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'S.E.C No. PG201636035 TEL #: (082)333-1809 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'LIST OF SERVICES ' + data, style: 'title'},
            '============================================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            {text: 'Branch: ' + this.branch, style: 'main_info'},
            {text: this.mode_of_report + ': ' + month+' '+this.ddaattaa, style: 'main_info'},
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: data_array,
                body: payments_array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: 'Cashier', style: 'cashier_placeholder'},
                    {text: ''},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Verified By: ', style: 'bm_top_margin'},
                    {text: 'Audited By: ', style: 'au_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'bm_for'},
                    {text: '', style: 'au_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'bm_line'},
                    {text: '_________________________________', style: 'au_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Chapel Manager', style: 'bm_placeholder'},
                    {text: 'Asst. Auditor', style: 'au_placeholder'},
                  ]
                },
              ]
            },

          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 10
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 9,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    },
  }
</script>
